import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from 'styled-components';

import Colors from '../../constants/colors';
import Fonts from '../../constants/fonts';
import Breakpoints from "../../constants/breakpoints";

const getData = graphql`
  query {
    objective: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Design_system/objective" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    oneloginColors: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Design_system/OneLogin/colors_and_typography" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    oneloginComponents: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Design_system/OneLogin/components" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    oneloginReactComponents: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Design_system/OneLogin/react_components" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    oracleIconGuideline: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Design_system/Oracle/icon_guideline" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    oracleGuidelineWebsite: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/Design_system/Oracle/guideline_website" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

function withImageData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={getData}
      render={data => <WrappedComponent {...props} imageData={data} />}
    />
  );
}

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
    flex-direction: column;
  }

  .container {
    display: flex;
    flex-direction: column;

    h4 {
      font-family: ${Fonts.Font_family.title_condensed};
      font-size: 1.375rem;
      line-height: 21px;
      color: ${props => props.lightText ? Colors.Basic.white : Colors.Text.default};
      margin-bottom: 32px;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 12px;
        height: 1px;
        background-color: ${Colors.Underline.blue};
        position: absolute;
        bottom: -8px;
        left: 0;
      }
    }
  }

`;

const ObjectiveImages = styled(ImageContainer)`
  margin-top: 32px;

  > div {
    width: 46.1102%;
    max-width: 569px;
    padding-top: 24px;
    position: relative;

    @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
      width: 48%;
    }
  
    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
      width: 100%;
      max-width: 400px;
    }

    &:not(:last-child) {
      margin-right: 7.7795%;

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        margin-right: 4%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }

  .container {
    h4 {
      margin-left: 16px;
      margin-bottom: 4px;
    }
    &:nth-child(2) {
      h4::after {
        background-color: ${Colors.Underline.pink};
      }
    }
  }
`;

const OneLoginColorsImages = styled(ImageContainer)`
  margin-bottom: 60px;
  
  > div {
    &:nth-child(1) {
      width: 29.4208%;
      max-width: 386px;
      margin-right: 4.5731%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 40px;
      }

      &::before {
        content: 'Colors';
        font-family: ${Fonts.Font_family.title_condensed};
        font-size: 1.375rem;
        line-height: 24px;
        color: ${Colors.Basic.white};
        position: absolute;
        top: 0;
        left: 16px;
      }
    }

    &:nth-child(2) {
      width: 27.4391%;
      max-width: 360px;
      margin-right: 9.1463%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 40px;
      }

      h4 {
        height: 21px;
        opacity: 0;

        @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
          display: none;
        }
      }
    }

    &:nth-child(3) {
      width: 29.4208%;
      max-width: 386px;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;

const OneLoginComponentsImages = styled(ImageContainer)`
  margin-bottom: 60px;
  
  > div {
    width: 24%;
    max-width: 313px;

    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }

    &:not(:last-child) {

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }

    img {
      border: 1px solid ${Colors.Basic.gray_95};
    }
  }
`;

const OneLoginReactComponentsImages = styled(ImageContainer)`
  justify-content: flex-end;

  @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
    flex-direction: column;
  }
  
  > div {
    
    &:nth-child(1) {
      width: 43.7894%;
      max-width: 416px;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }
    }
    &:nth-child(2) {
      width: 56.2105%;
      max-width: 534px;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;


const OracleImages = styled(ImageContainer)`
  > div {
    width: 31%;
  
    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 3.5%;


      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }

    img {
      border: 1px solid ${Colors.Basic.gray_92};
    }
  }
`;

export const Objective = withImageData(props => (
  <ObjectiveImages lightText data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.objective.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <div className="container" key={index}>
          <h4>{image.node.base.split("-")[1].split(".")[0]}</h4>
          <Img
            fluid={image.node.childImageSharp.fluid}
            alt={image.node.base.split("-")[1].split(".")[0]}
          />
        </div>
    ))}
  </ObjectiveImages>
));

export const OneLoginColors = withImageData(props => (
  <OneLoginColorsImages data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.oneloginColors.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <div className="container" key={index}>
          <h4>{image.node.base.split("-")[1].split(".")[0]}</h4>
          <Img
            fluid={image.node.childImageSharp.fluid}
            alt={image.node.base.split("-")[1].split(".")[0]}
          />
        </div>
    ))}
  </OneLoginColorsImages>
));

export const OneloginComponents = withImageData(props => (
  <OneLoginComponentsImages data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.oneloginComponents.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </OneLoginComponentsImages>
));

export const OneloginReactComponents = withImageData(props => (
  <OneLoginReactComponentsImages>
    {props.imageData.oneloginReactComponents.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </OneLoginReactComponentsImages>
));

export const OracleIconGuideline = withImageData(props => (
  <OracleImages>
    {props.imageData.oracleIconGuideline.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </OracleImages>
));

export const OracleGuidelineWebsite = withImageData(props => (
  <OracleImages>
    {props.imageData.oracleGuidelineWebsite.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
        />
    ))}
  </OracleImages>
));

