import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby';

import SEO from "../../components/seo";
import ProjectLayout from "../../components/layouts/projectLayout";
import Banner from "../../components/project/banner";
import ProjectSection from "../../components/project/projectSection";
import ProjectFooter from "../../components/project/projectFooter";
import ScreenshotsContainer from "../../components/project/screenshotsContainer";
import {ProjectSectionTitle, ProjectSectionsubtitle, ProjectSectionDescription, ProjectSectionList, ProjectSectionListItem} from "../../components/project/projectTypography";
import {Objective, OneLoginColors, OneloginComponents, OneloginReactComponents, OracleIconGuideline, OracleGuidelineWebsite} from "../../components/img/designSystemImages";


import Colors from "../../constants/colors";
import Fonts from "../../constants/fonts";
import Breakpoints from "../../constants/breakpoints";

// Images
import BannerImage from "../../images/project_banners/Project_banner-Design_System.png";
import BannerGrid from "../../images/projects/Design_system/banner_BG_grid.png";

const getProject = graphql`
  query {
    project:allContentfulProject(filter: {title:{eq: "Design System"}}) {
      edges {
        node {
          title
          description {
            description
          }
          slug
          order
          year
          role
          contentful_id
        }
      }
    }
  }
`;

const OneLoginComponentsTitle = styled.h4`
  font-family: ${Fonts.Font_family.title_condensed};
  font-size: 1.375rem;
  line-height: 21px;
  color: ${props => props.lightText ? Colors.Basic.white : Colors.Text.default};
  margin-bottom: 32px;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 1px;
    background-color: ${Colors.Underline.green};
    position: absolute;
    bottom: -8px;
    left: 0;
  }
`;

const ReactComponentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 320px;
  border: 1px solid ${Colors.Basic.gray_94};
  background-color: #1E2B30;
  border-radius: 6px;
  overflow: hidden;

  @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
    flex-direction: column;
    max-height: 100%;
  }

  .info {
    width: 25%;
    min-width: 240px;
    padding: 24px;

    @media only screen and (max-width: ${Breakpoints.Width.tablet}) {
      width: 100%; 
    }
    
    h4 {
      font-family: ${Fonts.Font_family.title_condensed};
      color: ${Colors.Basic.white};
      font-size: 1.375rem;
      line-height: 21px;
      margin-bottom: 32px;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 12px;
        height: 1px;
        background-color: ${Colors.Underline.yellow};
        position: absolute;
        bottom: -8px;
        left: 0;
      }
    }

    p {
      font-size: 0.875rem;
      line-height: 21px;
      color: ${Colors.Basic.white};
    }
  }

`;

const DesignSystem = () => {
  const data = useStaticQuery(getProject);
  const project = data.project.edges[0].node;
  const sections = [
    {
      "name": "Objective",
      "to": "objective"
    },
    {
      "name": "OneLogin",
      "to": "onelogin"
    },
    {
      "name": "Oracle",
      "to": "oracle"
    }
  ]

  return (  
    <ProjectLayout projectTitle={project.title} sections={sections}>
      <SEO title="Project - Design System" />
      <Banner
        lightText
        bgPattern={`url(${BannerGrid}),linear-gradient(90deg, #1D5273 0%, #13384D 100%)`}
        bgColor="#2B3744"
        bgImage={BannerImage}
        bgSize="auto 92%"
        bgPosY="0"
        title={project.title}
        description={project.description.description}
        year={project.year}
        role={project.role}
      />

      <ProjectSection bgColor="#363A3D" description="false" name={sections[0].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle color={Colors.Basic.white}>{sections[0].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle color={Colors.Basic.white}>
              Consistent standards across the organization
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description">
            <ProjectSectionDescription color={Colors.Basic.white}>
              A design system maintains a library of styles, components, and other elements. I led to building several comprehensive design systems, which help create a consistent user experience and manage design at scale.
            </ProjectSectionDescription>
          </div>
        </div>
        <Objective></Objective>
      </ProjectSection>

      <ProjectSection name={sections[1].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[1].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Build all foundations and guidelines from scratch
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description section-content__description--list">
            <ProjectSectionDescription>
              Before I joined, we didn't have a design system, so my top priority to redesign the new UI and build a design system simultaneously.
            </ProjectSectionDescription>
            <ProjectSectionList>
              <ProjectSectionListItem>Foundations — colors, typogrophy and grid system</ProjectSectionListItem>
              <ProjectSectionListItem>Components and layout patterns</ProjectSectionListItem>
              <ProjectSectionListItem>React.js component library</ProjectSectionListItem>
            </ProjectSectionList>
          </div>
        </div>
        <OneLoginColors></OneLoginColors>
        <OneLoginComponentsTitle data-sal="slide-up" data-sal-delay="400" data-sal-duration="800">Components</OneLoginComponentsTitle>
        <OneloginComponents></OneloginComponents>
        <ReactComponentsContainer data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
          <div className="info">
            <h4>React Components</h4>
            <p>Help create React.js library.</p>
          </div>
          <OneloginReactComponents></OneloginReactComponents>
        </ReactComponentsContainer>
      </ProjectSection>

      <ProjectSection bgColor="#F2F2F2" noDescription name={sections[2].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[2].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Refresh the components and centralize resources
            </ProjectSectionsubtitle>
          </div>
        </div>
        <ScreenshotsContainer infoWidth="20%" titleOffsetY="32px">
          <div className="info ui-activities">
            <h4>Icon Guideline</h4>
            <p>Create grid system and guideline for producing icons.</p>
          </div>
          <OracleIconGuideline></OracleIconGuideline>
        </ScreenshotsContainer>
        <ScreenshotsContainer infoWidth="20%" titleOffsetY="32px" underlineColor={Colors.Underline.green}>
          <div className="info ui-activities">
            <h4>Guideline Websites</h4>
            <p>Design and code the guideline/stencil websites.</p>
          </div>
          <OracleGuidelineWebsite></OracleGuidelineWebsite>
        </ScreenshotsContainer>
        
      </ProjectSection>

      <ProjectFooter currentProjectOrder="3">
      </ProjectFooter>
    </ProjectLayout>
  )
};

export default DesignSystem;
